import React from 'react';
import GatsbyLink from 'gatsby-link';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 150px 50px 0 50px;

  ${({ theme }) => theme.mq.desktop} {
    margin: 150px auto;
  }
`;

export default function FourOhFourPage() {
  return (
    <Wrapper>
      <h1>Strona nie istnieje</h1>
      <p>
        <GatsbyLink to="/">Idź to głównej</GatsbyLink>
      </p>
    </Wrapper>
  );
}
